<template>
  <div class="animation" :class="activeAnimation">
    <svg id="successAnimation" v-if="activeAnimation ==='success'" class="animated" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 70 70">
      <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#ffffff" stroke-width="2" stroke-linecap="round" fill="transparent"/>
      <polyline id="successAnimationCheck" stroke="#ffffff" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
    </svg>

    <svg id="failureAnimation" v-if="activeAnimation ==='failure'" class="animated" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 70 70">
      <circle id="failureAnimationCircle" cx="35" cy="35" r="24" stroke="#ffffff" stroke-width="2" stroke-linecap="round" fill="transparent"/>
      <polyline class="failureAnimationCheckLine" stroke="#ffffff" stroke-width="2" points="25,25 45,45" fill="transparent" />
      <polyline class="failureAnimationCheckLine" stroke="#ffffff" stroke-width="2" points="45,25 25,45" fill="transparent" />
    </svg>
  </div>
</template>

<script>
    export default {
        name: "SuccessFailAnimation",
        data () {
            return {
                activeAnimation: 'none'
            }
        },
        methods: {
            showSuccess() {
                this.hide();
                this.$nextTick(() => {
                    this.activeAnimation = 'success';
                })

            },
            showFailure() {
                this.hide();
                this.$nextTick(() => {
                    this.activeAnimation = 'failure';
                })

            },
            hide () {
              this.activeAnimation = 'none';
            }
        }
    }
</script>
