<template>
    <div class="qr-scan" :class="[ statusClass, { 'qr-code-enabled': qrCodeEnabled && hasQrCodeReader, compact } ]">
        <div class="qr-area">
            <qrcode-stream
                v-if="hasQrCodeReader && qrCodeEnabled"
                @decode="onDecode"
                @init="onQrcodeInit"
                width="100%"
                height="100%"
                :torch="torchEnabled"
                :camera="qrCodeCamera"
                :lastScanResetTimeout="3000"
            ></qrcode-stream>
            <div class="frame" v-if="hasQrCodeReader && qrCodeEnabled"></div>
            <div class="overlay text-center">
                <div class="help noselect">
                    {{ text }}
                </div>
                <div class="information noselect">{{ information }}</div>
                <span class="key noselect">{{ keyHistory }}</span>

                <v-text-field
                    v-if="showSearchInput"
                    v-model="searchText"
                    label="Zoek"
                    placeholder="Zoeken..."
                    class="ma-5 hidden-sm-and-down"
                    @focus="stopKeyListener"
                    @blur="startKeyListener"
                    @keyup="queueSearchTextUpdate(300)"
                    @keyup.enter="onSearchTextEnter"
                    solo
                    clearable
                    ref="searchInput"
                ></v-text-field>

                <v-card
                    v-if="searchResults"
                    outlined
                    elevation="0"
                    class="mx-5"
                >
                    <v-list two-line>
                        <v-list-item
                            v-for="item in searchResults"
                            :key="item.id"
                            @click="selectSearchResult(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span :class="{'text-decoration-line-through': item.isDeleted}">{{ item.title }}</span>
                                    <v-chip
                                        v-if="item.badge"
                                        :color="item.badge.color"
                                        label
                                        x-small
                                        class="ml-2"
                                    >{{item.badge.label}}</v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle :class="{'text-decoration-line-through': item.isDeleted}">{{ item.subtitle }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

                <SuccessFailAnimation v-show="!compact" ref="animation"></SuccessFailAnimation>

                <div v-if="hasBlockingWarning" class="confirm-action">
                    <v-btn @click="confirmAction" x-large>Bevestigen</v-btn>
                </div>

                <div class="actions d-flex">
                    <v-switch
                        v-model="qrCodeEnabledSwitch"
                        v-if="!autoEnableQrCodeReader && hasQrCodeReader && !isMobile"
                        class="mr-7">
                        <template v-slot:label>
                            <v-icon class="ml-1">fal fa-qrcode</v-icon>
                        </template>
                    </v-switch>
                    <v-switch v-model="torchEnabled" v-if="hasQrCodeReader && hasTorch">
                        <template v-slot:label>
                            <v-icon class="ml-1">fal fa-flashlight</v-icon>
                        </template>
                    </v-switch>
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>

        <audio ref="beepSuccess" src="@/assets/audio/beep-success.mp3" preload></audio>
        <audio ref="beepError" src="@/assets/audio/beep-error.mp3" preload></audio>
        <audio ref="beepWarning" src="@/assets/audio/beep-warning.mp3" preload></audio>
    </div>
</template>

<script>
import interop from "@interop/index";
import SuccessFailAnimation from "@/components/animation/SuccessFailAnimation";
import {QrcodeStream} from "vue-qrcode-reader";

let resetTimer;
let searchTimer;
let autofocusTimer;

export default {
    name: "ScanInput",
    components: {SuccessFailAnimation, QrcodeStream},
    props: {
        showSearchInput: {
            type: Boolean,
            default: false
        },
        defaultText: {
            type: String,
            default: 'Scan een ticket'
        },
        autoEnableQrCodeReader: {
            type: Boolean,
            default: false
        },
        searchResults: {
            type: Array,
            required: false
        },
        enableKeyInput: {
            type: Boolean,
            default: true
        },
        compact: {
            type: Boolean,
            default: false
        },
        autoFocusSearch: {
            type: Boolean,
            default: true
        }
    },

    mounted() {
        this.startKeyListener()

        if (this.hasQrCodeReader) {
            this.qrCodeEnabled = this.isMobile || this.autoEnableQrCodeReader || this.$store.state.app.autoEnableQrCodeReader
        }

        this.text = this.defaultText;

        this.focusSearch();
        autofocusTimer = window.setInterval(() => {
            this.focusSearch();
        }, 3000);
    },

    beforeDestroy() {
        this.stopKeyListener()
        window.clearInterval(autofocusTimer);
    },

    data() {
        return {
            text: null,
            information: null,
            status: 'grey',
            keyHistory: '',
            qrCodeEnabled: true,
            qrCodeCamera: 'auto',
            torchEnabled: false,
            hasQrCodeReader: interop.webcam,
            hasTorch: false,
            searchText: null,
            hasBlockingWarning: false
        }
    },

    computed: {
        isMobile() {
            return this.$store.state.app.isMobile
        },
        statusClass() {
            return 'status-' + this.status;
        },
        qrCodeEnabledSwitch: {
            get() {
                return this.qrCodeEnabled
            },
            set(val) {
                this.qrCodeEnabled = val;
                this.$store.commit('SET_QRCODE_AUTO_ENABLE', val);
            }
        },
        isMobileSafari() {
            return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
        }
    },

    methods: {
        onDecode(decodedString) {
            this.turnCameraOff();
            this.$emit('input', decodedString.trim());
        },
        onKeyPress(e) {
            if (!this.enableKeyInput) {
                return;
            }

            if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
                this.readFromClipboard();
            } else if ((e.key.length === 1) && (/[a-zA-Z0-9]/.test(e.key))) {
                this.keyHistory += e.key;
            } else if (e.key === 'Enter') {
                if (this.keyHistory) {
                    this.$emit('input', this.keyHistory.trim());
                }

                this.keyHistory = '';
            } else {
                this.keyHistory = '';
            }
        },
        onScanDetected(e) {
            let dataString = e.detail
            this.$emit('input', dataString.trim());
        },
        showSuccessStatus(text, information) {
            this.status = 'success';
            this.text = text;
            this.information = information || null;
            this.$refs.animation.showSuccess();
            this.vibrate(20);
            this.playSound('beepSuccess');
            this.queueReset();
            this.resumeCamera();
        },
        showWarningStatus(text, information, mustConfirm) {
            this.status = 'warning';
            this.text = text;
            this.information = information || null;
            this.$refs.animation.showSuccess();
            this.vibrate(500);
            this.playSound('beepWarning');
            this.hasBlockingWarning = mustConfirm;

            if (!mustConfirm) {
                this.resumeCamera();
                this.queueReset(7000);
            }
        },
        showErrorStatus(text, information) {
            this.status = 'error';
            this.text = text;
            this.information = information || null;
            this.$refs.animation.showFailure();
            this.vibrate(500)
            this.playSound('beepError');
            this.queueReset();
            this.resumeCamera();
        },
        confirmAction() {
            this.resumeCamera()
            this.resetScreen()
            this.hasBlockingWarning = false
        },
        vibrate(pattern) {
            if (typeof window.navigator.vibrate === 'function') {
                window.navigator.vibrate(pattern);
            }
        },
        playSound(ref) {
            if (this.isMobileSafari) {
                // https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
                console.log('ignore soundgit pu ' + ref + ' on Safari (not supported)');
                return;
            }
            try {
                this.$refs[ref].play();
            } catch (e) {
                console.error(`Failed to play sound ${ref}: `, e);
            }
        },
        queueReset(timeout) {
            if (resetTimer) {
                window.clearTimeout(resetTimer);
            }
            resetTimer = window.setTimeout(() => {
                this.resetScreen();
            }, timeout || 3000);
        },
        resetScreen() {
            this.status = 'grey';
            this.text = this.defaultText;
            this.information = null;
            if (this.$refs.animation) {
                this.$refs.animation.hide();
            }
        },

        resumeCamera() {
            //this.qrCodeCamera = 'auto'
        },
        turnCameraOff() {
            //this.qrCodeCamera = 'off'
        },
        readFromClipboard() {
            navigator.permissions.query({name: "clipboard-read"}).then(result => {
                // If permission to read the clipboard is granted or if the user will
                // be prompted to allow it, we proceed.

                if (result.state == "granted" || result.state == "prompt") {
                    navigator.clipboard.readText().then(clipText => {
                        this.$emit('input', clipText.trim());
                    });
                }
            });
        },
        async onQrcodeInit(promise) {
            // show loading indicator

            try {
                const {capabilities} = await promise
                this.hasTorch = !!capabilities.torch
                //console.log('capabilities', capabilities);

                // successfully initialized
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    // user denied camera access permisson
                    this.$store.dispatch('showSnackbar', {text: 'Geen toegang tot camera', color: 'error'});
                } else if (error.name === 'NotFoundError') {
                    // no suitable camera device installed
                    this.$store.dispatch('showSnackbar', {text: 'Geen camera gevonden', color: 'error'});
                } else if (error.name === 'NotSupportedError') {
                    // page is not served over HTTPS (or localhost)
                    this.$store.dispatch('showSnackbar', {text: 'Camera niet ondersteund', color: 'error'});
                } else if (error.name === 'NotReadableError') {
                    // maybe camera is already in use
                    this.$store.dispatch('showSnackbar', {text: 'Geen verbinding met camera (al in gebruik?)', color: 'error'});
                } else if (error.name === 'OverconstrainedError') {
                    // did you requested the front camera although there is none?
                    this.$store.dispatch('showSnackbar', {text: 'Camera niet gevonden', color: 'error'});
                } else if (error.name === 'StreamApiNotSupportedError') {
                    // browser seems to be lacking features
                    this.$store.dispatch('showSnackbar', {text: 'Browser heeft geen ondersteuning', color: 'error'});
                }
            } finally {
                // hide loading indicator
            }
        },
        queueSearchTextUpdate(timeout) {
            if (searchTimer) {
                window.clearTimeout(searchTimer);
            }
            searchTimer = window.setTimeout(() => {
                this.updateSearchText();
            }, timeout || 300);
        },
        onSearchTextEnter() {
            if (searchTimer) {
                window.clearTimeout(searchTimer);
            }
            this.$emit('input', this.searchText.trim());
            this.clearSearchText();
        },
        updateSearchText() {
            this.$emit('searchTextChanged', this.searchText);
        },
        selectSearchResult(item) {
            this.$emit('searchOptionSelected', item)
        },
        startKeyListener() {
            window.addEventListener('keypress', this.onKeyPress)
            window.addEventListener('scan', this.onScanDetected);
        },
        stopKeyListener() {
            window.removeEventListener('keypress', this.onKeyPress)
            window.removeEventListener('scan', this.onScanDetected);
        },
        clearSearchText() {
            this.searchText = '';
        },
        focusSearch() {
            if (this.showSearchInput && this.enableKeyInput) {
                this.$refs.searchInput.focus();
            }
        }
    }
}
</script>
